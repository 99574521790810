import React from "react"
import { Container, Col, Row, Card } from 'react-bootstrap'
import SectionTitle from "./sectionTitle"
import styled from "styled-components"
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = () => {
  return (
    <>
      <Container id="contact" className="py-5 mt-5">
        <Row className="justify-content-center">
            <Col lg={12}>
              <SectionTitle title={"Express Interest"}/>
            </Col>
            <Col>
              <form
                name="contact"
                id="contactForm"
                className="form-custom my-5 p-5"
                method="POST"
                netlify="true"
                action="../success"
              >
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="contact" />
                <h4 className="form-head">Let's Connect</h4>
                <p className="form-p" type="Name:">
                  <input
                    type="text"
                    name="Name"
                    id="userName"
                    autocomplete="off"
                    className="form-input"
                    placeholder="Write your name here...">
                  </input>
                </p>

                <p className="form-p" type="Email:">
                  <input
                    type="email"
                    name="Email"
                    id="userEmail"
                    autocomplete="off"
                    className="form-input"
                    placeholder="How can we get back...">
                  </input>
                </p>

                <p className="form-p" type="Message:">
                  <textarea name="Message" id="userMessage" rows="3"className="form-input" placeholder="Share your thoughts..."></textarea>
                </p>

                <div data-nelify-recaptcha="true"></div>

                <button id="submit" className="form-b" type="submit">Send Message</button>
                <div className="peek-out">
                  <a className="peek" href="tel:+917-727-5506">
                    <span className="fa fa-phone"></span>  (917) 727-5506
                  </a>
                  <a className="peek" href="mailto:contact@chronogrp.com?Subject=Visited%20Website%20--">
                    <span className="fa fa-envelope"></span>  contact@chronogrp.com
                  </a>

                </div>

              </form>
            </Col>
        </Row>
        <Footer>
          © {new Date().getFullYear()}, Chrono Group LLC
        </Footer>
      </Container>
    </>
  )
}

const Footer = styled.footer`
  text-align: center;
  margin: 24px;
`

export default ContactForm
