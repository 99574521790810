import React from "react"
import styled from "styled-components"


const SectionTitle = (props) =>{
  return(
    <>
      <div className="col-12 os-animation" data-animation="fadeInUp">
        <h3 className="heading">{props.title}</h3>
        <div className="heading-underline"></div>
      </div>
    </>
  )
}

export default SectionTitle
